import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"

import { Section, Container } from "../global"

const Features = () => {
  const {allFile: featureImages} = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: {sourceInstanceName: {eq: "feature"}}) {
        totalCount
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }

  `);
  console.log(featureImages);

  return (
    <Section id="features">
      <StyledContainer>
        <Subtitle>Features</Subtitle>
        <SectionTitle>Food-Specific Graphic Design</SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <ImageWrapper>
              <StyledImage fluid={featureImages.nodes[1].childImageSharp.fluid} />
              <br />
            </ImageWrapper>
            <FeatureTitle>Illustrated Ingredients</FeatureTitle>

            <FeatureText>
              Professionally designed icons of any ingredient you can think of.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <ImageWrapper>
              <StyledImage fluid={featureImages.nodes[2].childImageSharp.fluid} />
              <br />
            </ImageWrapper>
            <FeatureTitle>Fast</FeatureTitle>
            <FeatureText>
              In the time it takes to type out the recipe, you have a graphic for your post.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <ImageWrapper>
              <StyledImage fluid={featureImages.nodes[0].childImageSharp.fluid} />
              <br />
            </ImageWrapper>
            <FeatureTitle>Legal</FeatureTitle>
            <FeatureText>
              Our icons are licensed for re-use unlike most everything else you'll find online.
            </FeatureText>
          </FeatureItem>
        </FeaturesGrid>
      </StyledContainer>
    </Section>
  )
}

export default Features;

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 200px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100px;
    /* display: none; */
  }
`
